const { BROWSER_NAME } = require('../../constants/app');

/**
 * Checks if the given browser name is Safari or Mobile Safari.
 *
 * @param {string} browserName - The name of the browser.
 * @returns {boolean} - Returns true if the browser name is Safari or Mobile Safari, otherwise returns false.
 */
const isSafariBrowser = (browserName) => [BROWSER_NAME.SAFARI, BROWSER_NAME.MOBILE_SAFARI].includes(browserName);

module.exports = isSafariBrowser;
