const React = require('react');
const PropTypes = require('prop-types');
const path = require('path');

const { v4: uuidv4 } = require('uuid');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { loadable, LazyHydrate } = require('nordic/lazy');

const Optimus = loadable(() => import('../../containers/Optimus')); // Hack to preload Optimus on skeleton and have a better LCP and TTI
const { getQueryParams } = require('../../utils/Dom');
const STEP_ACTIONS = require('../../spa/actions/step');

/* Own component */
const SkeletonFull = require('../../components/SkeletonFull');
const Skeleton = require('../../components/Skeleton');
const Page = require('../../components/Page');
const { isIframe } = require('../../utils/Dom');
const { checkIfCookiesAreBlocked } = require('../../utils/cookie');
const { CREATE_FLOW } = require('../../spa/actions/types');
const { DEVICE_TYPE } = require('../../../constants/commons');
const { trackMelidata } = require('../../utils/Tracker');
const { logErrorFromClient } = require('../../utils/logTags');
const isSafariBrowser = require('../../utils/isSafariBrowser');
const {
  ERROR_SPA: { SNIFFING_TRACKING_ERROR, SKELETON_TRACKING_ERROR, SNIFFING_SAFARI_ERROR },
  SNIFFING_ATTEMPT_STATUS,
  QUERY_PARAMS,
  CHECKOUT_TYPE,
} = require('../../../constants/app');

const cleanSniffingAndRefresh = (url) => {
  url.searchParams.delete(QUERY_PARAMS.SNIFFING_ROLLOUT);
  url.searchParams.delete(QUERY_PARAMS.SNIFFING_FORCE_APP);
  url.searchParams.delete(QUERY_PARAMS.SNIFFING_FORCE_STRATEGY);
  url.searchParams.set(QUERY_PARAMS.NAVIGATE_TO_APP, 'false');
  window.location.replace(url);
};

const SkeletonScreen = (props) => {
  const {
    deviceType,
    stepTitle,
    currentStep,
    firstRender,
    checkout,
    stepActions,
    flow,
    history,
    basePath,
    isWebview,
    browserName,
  } = props;
  const createFlow = React.useRef(true);
  let nextRedirect;
  const DELAY = isSafariBrowser(browserName) ? 25 : 2_000;
  let wasRedirectedToApp = false;
  let sniffingAttempt; // TODO Delete when growth coupon incentive ends

  const redirectToApps = () =>
    new Promise((resolve) => {
      const redirectRecursive = () => {
        if (props.sniffing.urls.length) {
          const url = props.sniffing.urls.shift();
          if (url.length) {
            window.location.replace(url);
          }
          setTimeout(redirectRecursive, DELAY);
        } else {
          resolve();
        }
      };
      redirectRecursive();
    });

  const handleVisibilityState = () => {
    clearTimeout(nextRedirect);
    wasRedirectedToApp = true;
    // To regenerate router request id if checkout is reopened in the browser.
    const url = new URL(window.location);
    url.searchParams.set(QUERY_PARAMS.ROUTER_REQUEST_ID, uuidv4());
    window.history.pushState({}, '', url);
    // We are closing the tab to avoid having a checkout open in the background in the browser.
    // This works in Android but not in iOS (TODO: Find a way that works on both)
    window.close();
  };

  const skeletonHandler = () => {
    // Adding the Skeleton Tracking Data
    try {
      if (Object.keys(props.sniffing?.skeletonTrackingData ?? {}).length) {
        const { melidata } = props.sniffing.skeletonTrackingData;
        trackMelidata(
          {
            path: melidata.path,
            payload: melidata.data,
          },
          props,
        );
      }
    } catch (err) {
      logErrorFromClient(err, SKELETON_TRACKING_ERROR, '[SkeletonScreen][skeletonHandler]');
    }

    if (isIframe()) {
      if (checkIfCookiesAreBlocked()) {
        createFlow.current = false;
        window.location.replace(path.join(basePath, `/${checkout.type}/restart-flow${window.location.search}`));
      }
    }

    if (firstRender && createFlow.current) {
      checkout.is_redirect_iframe = checkout.type === CHECKOUT_TYPE.REDIRECT && isIframe();
      // TODO Delete when growth coupon incentive ends
      checkout.sniffing_attempt = sniffingAttempt || SNIFFING_ATTEMPT_STATUS.NO_SNIFFING_ATTEMPT;
      stepActions[CREATE_FLOW](flow, getQueryParams(), checkout, history);
    }
  };

  const sniffingHandler = async () => {
    // We are using two event listener to cover some cases where only one of them works (Browsers, contexts, etc...)
    window.addEventListener('visibilitychange', handleVisibilityState, true);
    window.addEventListener('pagehide', handleVisibilityState, true);

    await redirectToApps();

    if (!wasRedirectedToApp) {
      // TODO Delete when growth coupon incentive ends
      sniffingAttempt = SNIFFING_ATTEMPT_STATUS.FAILED;
      window.removeEventListener('visibilitychange', handleVisibilityState, true);
      window.removeEventListener('pagehide', handleVisibilityState, true);
      /* In case the user was not redirected to the app for any reason, we need to track the new skeleton flow.
      For catch this cases we are already sending/mapping the "skeletonTrackingData" values from the backend in the SniffingMiddleware */
      try {
        if (Object.keys(props.sniffing.skeletonTrackingData ?? {}).length) {
          const { melidata } = props.sniffing.skeletonTrackingData;
          trackMelidata(
            {
              path: melidata.path,
              payload: melidata.data,
            },
            props,
          );
        }
      } catch (err) {
        logErrorFromClient(err, SNIFFING_TRACKING_ERROR, '[SkeletonScreen][sniffingHandler]');
      }

      const checkoutUrl = new URL(window.location.href);
      if (isSafariBrowser(browserName)) {
        if (checkoutUrl.searchParams.has(QUERY_PARAMS.PREFERENCE_ID)) {
          cleanSniffingAndRefresh(checkoutUrl);
        } else {
          logErrorFromClient(
            new Error('Could not get window location at Safari'),
            SNIFFING_SAFARI_ERROR,
            '[SkeletonScreen][sniffingHandler]',
          );
          skeletonHandler();
        }
      } else {
        skeletonHandler();
      }
    }
    // TODO Delete when growth coupon incentive ends
    sniffingAttempt = SNIFFING_ATTEMPT_STATUS.SUCCESSFUL;
  };

  React.useEffect(() => {
    if (deviceType === DEVICE_TYPE.MOBILE && props.sniffing?.urls?.length && !isWebview) {
      sniffingHandler();
    } else {
      skeletonHandler();
    }
  }, []);

  return (
    <Page title={stepTitle} currentStep={currentStep} deviceType={deviceType} showLoader={false} checkForCookie={false}>
      {deviceType === DEVICE_TYPE.DESKTOP ? <SkeletonFull /> : <Skeleton />}
      {/* Hack for preload optimus on skeleton to have a better LCP and TTI */}
      <div style={{ display: 'hidden' }}>
        <LazyHydrate whenIdle>
          <Optimus
            components={[]}
            history={{}}
            onStepSubmit={() => null}
            siteId=""
            urls=""
            deviceType=""
            publicKey=""
            hiddenComponents=""
          />
        </LazyHydrate>
      </div>
    </Page>
  );
};

/**
 * Default Values
 */
SkeletonScreen.defaultProps = {
  stepTitle: '',
  currentStep: '',
  deviceType: '',
};

/**
 * PropTypes
 */
SkeletonScreen.propTypes = {
  stepTitle: PropTypes.string,
  currentStep: PropTypes.string,
  deviceType: PropTypes.string,
  sniffing: PropTypes.object,
  checkout: PropTypes.object,
  firstRender: PropTypes.bool,
  history: PropTypes.object,
  basePath: PropTypes.string,
  stepActions: PropTypes.object,
  flow: PropTypes.object,
  isWebview: PropTypes.bool,
  browserName: PropTypes.string,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  isWebview: state.configurations.isWebview,
});

module.exports =
  process.env.NODE_ENV === 'test' ? SkeletonScreen : connect(mapStateToProps, mapDispatchToProps)(SkeletonScreen);
